
//const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//${API_BASE_URL}
// Fetch all accounts
export const fetchAccounts = async () => {
  const response = await fetch(`/api/ea_info`);
  if (!response.ok) {
    throw new Error('Failed to fetch accounts');
  }
  return response.json();
};

// Create a new account
export const createAccount = async (account) => {
  const response = await fetch(`/api/ea_info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(account),
  });
  if (!response.ok) {
    throw new Error('Failed to create account');
  }
  return response.json();
};

// Update an existing account
export const updateAccount = async (id, account) => {
  const response = await fetch(`/api/ea_info/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(account),
  });
  if (!response.ok) {
    throw new Error('Failed to update account');
  }
  return response.json();
};

// Delete an account
export const deleteAccount = async (id) => {
  const response = await fetch(`/api/ea_info/${id}`, {
    method: 'DELETE',
  });
  if (!response.ok) {
    throw new Error('Failed to delete account');
  }
  return response.json();
};
