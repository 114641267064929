import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../assets/css/styles.css';
import loginBg from '../assets/img/login-bg.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Access the login function from AuthContext

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ UserName: username, Password: password }),
      });
      console.clear();
      setLoading(false); // Hide loader

      if (!response.ok) {
        if (response.status === 401) {
          setErrorMessage('Invalid username or password');
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
        return;
      }
      localStorage.setItem('username', username);
      // Call the login function from AuthContext upon successful login
      login();
      setErrorMessage('');
      navigate('/dashboard');
    } catch (error) {
      setLoading(false); // Hide loader
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="login">
      <img src={loginBg} alt="Login background" className="login__bg" />

      <form onSubmit={handleSubmit} className="login__form">
        <h1 className="login__title">Login</h1>

        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {loading && <div className="loader"></div>} {/* Loader */}

        <div className="login__inputs">
          <div className="login__box">
            <input
              type="text"
              placeholder="User ID"
              required
              className="login__input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <i className="ri-shield-user-line"></i>
          </div>

          <div className="login__box">
            <input
              type="password"
              placeholder="Password"
              required
              className="login__input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i className="ri-lock-2-fill"></i>
          </div>
        </div>

        <button type="submit" className="login__button" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>

        <div className="login__register">
          Don't have an account or forgot login/password{' '}
          <a
            className="mail"
            href="mailto:contact@tradingischess.com?subject=License Dashboard Subject!"
            style={{ color: 'blue' }}
          >
            CONTACT US
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
