import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../assets/css/dashboard.css';
import loginBg from '../assets/img/login-bg.png';
import { fetchAccounts, createAccount, updateAccount, deleteAccount } from '../api/eaInfoAPI';

const Dashboard = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [username] = useState(localStorage.getItem('username') || 'Username');
  const [isAdding, setIsAdding] = useState(false);
  const [originalAccount, setOriginalAccount] = useState(null);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const data = await fetchAccounts();
        setAccounts(data);
      } catch (error) {
        console.error('Failed to load accounts', error);
      }
    };
    loadAccounts();
  }, []);

  const handleLogout = () => {
    logout();
    localStorage.removeItem('username');
    navigate('/');
  };

  const addAccountRow = () => {
    if (isAdding) return; // Prevent adding multiple empty rows
    const id = accounts.length ? accounts[accounts.length - 1].id + 1 : 1;
    const newAccount = {
      id,
      Account_Name: '',
      Account_Number: '',
      Broker_Name: '',
      Broker_Server: '',
      Expiration_Date: '',
      Is_Name_Checked: true,
      Is_Number_Checked: true,
      Is_Broker_Checked: true,
      Is_Server_Checked: true,
      Is_Expiration_Checked: true,
      isEditing: true,
      isNew: true
    };
    setAccounts([...accounts, newAccount]);
    setIsAdding(true);

    setTimeout(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  const saveNewAccount = async (id) => {
    const account = accounts.find(account => account.id === id);
    if (!account) return;

    const accountData = {
      Account_Name: account.Account_Name,
      Account_Number: account.Account_Number,
      Broker_Name: account.Broker_Name,
      Broker_Server: account.Broker_Server,
      Expiration_Date: account.Expiration_Date,
      Is_Name_Checked: true,
      Is_Number_Checked: account.Is_Number_Checked,
      Is_Broker_Checked: account.Is_Broker_Checked,
      Is_Server_Checked: account.Is_Server_Checked,
      Is_Expiration_Checked: account.Is_Expiration_Checked
    };

    try {
      const result = account.isNew ? await createAccount(accountData) : await updateAccount(id, accountData);

      if (result) {
        const updatedAccounts = await fetchAccounts();
        setAccounts(updatedAccounts);
        setIsAdding(false);
      } else {
        alert('Failed to save account');
      }
    } catch (error) {
      if (error.message.includes('409')) {
        alert('Account number and broker combination already exists');
      } else {
        alert('Failed to save account');
      }
    }
  };

  const saveModifiedAccount = async (id) => {
    const account = accounts.find(account => account.id === id);
    if (!account) return;

    const accountData = {
      Account_Name: account.Account_Name,
      Account_Number: account.Account_Number,
      Broker_Name: account.Broker_Name,
      Broker_Server: account.Broker_Server,
      Expiration_Date: account.Expiration_Date,
      Is_Name_Checked: true,
      Is_Number_Checked: account.Is_Number_Checked,
      Is_Broker_Checked: account.Is_Broker_Checked,
      Is_Server_Checked: account.Is_Server_Checked,
      Is_Expiration_Checked: account.Is_Expiration_Checked
    };

    const expirationDate = document.getElementById(`editExpiration${id}`)?.value;
    if (expirationDate) {
      accountData.Expiration_Date = expirationDate;
    }

    try {
      const result = await updateAccount(id, accountData);

      if (result) {
        const updatedAccounts = await fetchAccounts();
        setAccounts(updatedAccounts);
        setOriginalAccount(null);
        setIsAdding(false); // Disable adding when modification is done
      } else {
        alert('Failed to update account');
      }
    } catch (error) {
      console.error('Failed to update account', error);
    }
  };

  const cancelEdit = (id) => {
    if (accounts.find(account => account.id === id && account.isNew)) {
      const updatedAccounts = accounts.filter(account => account.id !== id);
      setAccounts(updatedAccounts);
    } else {
      const updatedAccounts = accounts.map(account =>
        account.id === id ? { ...originalAccount, isEditing: false } : account
      );
      setAccounts(updatedAccounts);
      setOriginalAccount(null);
    }
    setIsAdding(false);
  };

  const removeAccount = async (id) => {
    try {
      const result = await deleteAccount(id);

      if (result) {
        const updatedAccounts = await fetchAccounts();
        setAccounts(updatedAccounts);
        setIsAdding(false);
      } else {
        alert('Failed to delete account');
      }
    } catch (error) {
      console.error('Failed to delete account', error);
    }
  };

  const handleInputChange = (id, field, value) => {
    const updatedAccounts = accounts.map(account =>
      account.id === id ? { ...account, [field]: value } : account
    );
    setAccounts(updatedAccounts);
  };

  const handleCheckboxChange = (id, field, checked) => {
    const updatedAccounts = accounts.map(account =>
      account.id === id ? { ...account, [field]: checked } : account
    );
    setAccounts(updatedAccounts);
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Return empty string if dateString is falsy
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const modifyAccountRow = (id) => {
    const account = accounts.find(account => account.id === id);
    setOriginalAccount({ ...account });
    const updatedAccounts = accounts.map(acc =>
      acc.id === id ? { ...acc, isEditing: true, isNew: false } : acc
    );
    setAccounts(updatedAccounts);
    setIsAdding(true); // Disable adding when modifying
  };

  return (
    <div className="dashboard">
      <img src={loginBg} alt="Dashboard background" className="dashboard__bg" />

      <div className="dashboard-header-container">
        <header className="dashboard-header">
          <div className="dashboard-username-display">{username}</div>
          <h3 className="title_0">Dashboard Center SYNTHEX-EA</h3>
          <button className="dashboard-logout-btn" onClick={handleLogout}>Logout</button>
        </header>
      </div>

      <div className="dashboard-container">
        <div className="dashboard-table-container" ref={tableContainerRef}>
          <table id="accountTable" className="dashboard-table">
            <thead>
              <tr>
                <th className="id-column">ID</th>
                <th>Account Name</th>
                <th>Account Number</th>
                <th>Broker Name</th>
                <th>Broker Server</th>
                <th>Expiration Date</th>
                <th className="actions-column">Actions</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map(account => (
                <tr key={account.id}>
                  {account.isEditing ? (
                    <>
                      <td>{account.id}</td>
                      <td>
                        <div className="cell-content">
                          <input
                            type="text"
                            value={account.Account_Name}
                            onChange={e => handleInputChange(account.id, 'Account_Name', e.target.value)}
                            className="dashboard-input"
                          />
                          <input
                            type="checkbox"
                            checked={true}
                            className="dashboard-checkbox"
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          <input
                            type="number"
                            value={account.Account_Number}
                            onChange={e => handleInputChange(account.id, 'Account_Number', e.target.value)}
                            className="dashboard-input"
                          />
                          <input
                            type="checkbox"
                            checked={account.Is_Number_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Number_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          <input
                            type="text"
                            value={account.Broker_Name}
                            onChange={e => handleInputChange(account.id, 'Broker_Name', e.target.value)}
                            className="dashboard-input"
                          />
                          <input
                            type="checkbox"
                            checked={account.Is_Broker_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Broker_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          <input
                            type="text"
                            value={account.Broker_Server}
                            onChange={e => handleInputChange(account.id, 'Broker_Server', e.target.value)}
                            className="dashboard-input"
                          />
                          <input
                            type="checkbox"
                            checked={account.Is_Server_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Server_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          <input
                            type="date"
                            id={`editExpiration${account.id}`}
                            value={formatDate(account.Expiration_Date)}
                            onChange={e => handleInputChange(account.id, 'Expiration_Date', e.target.value)}
                            className="dashboard-input"
                          />
                          <input
                            type="checkbox"
                            checked={account.Is_Expiration_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Expiration_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                          />
                        </div>
                      </td>
                      <td className="dashboard-actions">
                        <button
                          className="dashboard-save-btn"
                          onClick={() => account.isNew ? saveNewAccount(account.id) : saveModifiedAccount(account.id)}
                        >
                          Save
                        </button>
                        <button className="dashboard-cancel-btn" onClick={() => cancelEdit(account.id)}>Cancel</button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{account.id}</td>
                      <td>
                        <div className="cell-content">
                          {account.Account_Name}
                          <input
                            type="checkbox"
                            checked={true}
                            className="dashboard-checkbox"
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          {account.Account_Number}
                          <input
                            type="checkbox"
                            checked={account.Is_Number_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Number_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                            disabled={!account.isEditing}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          {account.Broker_Name}
                          <input
                            type="checkbox"
                            checked={account.Is_Broker_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Broker_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                            disabled={!account.isEditing}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          {account.Broker_Server}
                          <input
                            type="checkbox"
                            checked={account.Is_Server_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Server_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                            disabled={!account.isEditing}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="cell-content">
                          {formatDate(account.Expiration_Date)}
                          <input
                            type="checkbox"
                            checked={account.Is_Expiration_Checked}
                            onChange={e => handleCheckboxChange(account.id, 'Is_Expiration_Checked', e.target.checked)}
                            className="dashboard-checkbox"
                            disabled={!account.isEditing}
                          />
                        </div>
                      </td>
                      <td className="dashboard-actions">
                        <button className="dashboard-modify-btn" onClick={() => modifyAccountRow(account.id)}>Modify</button>
                        <button className="dashboard-remove-btn" onClick={() => removeAccount(account.id)}>Remove</button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button id="addAccountBtn" className="dashboard-add-account-btn" onClick={addAccountRow} disabled={isAdding}>Add Account</button>
      </div>
    </div>
  );
};

export default Dashboard;
